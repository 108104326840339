export default {
  common: {
    username: "用户名",
    password: "密码",
    login: "登录",
    logout: "登出",
    setting: "设置",
    submit: "提交",
    re_login: "重新登陆",
    print_in_warehouse_order: "打印送仓单",
    remember_me: "记住我",
    p0_is_required: "{0}是必填的.",
    p0_is_not_complete: "{0}未填写完整.",
    p0_must_be_at_least_p1_characters: "{0}至少{1}个字符",
    p0_up_to_p1_characters: "{0}最多{1}个字符",
    p0_cannot_be_empty_or_0: "{0}不能为空或为0",
    p0_incorrect_format : "{0}格式不正确",
    the_number_of_successes_is_p0 : "成功数量为{0}",
    the_number_of_fail_is_p0 : "失败数量为{0}",
    fail_order : "失败订单",
    print: "打印",
    no_data: "没有相关数据",
    succeed: "成功",
    the_format_of_the_uploaded_file_is_not_supported: "上传的文件格式不支持",
    failed: "失败",
    are_you_sure: "您确定吗?",
    successfully_canceled: "取消成功",
    days:"天",
    back_step: "上一步",
    next_step: "下一步",
    save_and_next_step: "保存&下一步",
    confirm_and_next_step: "确定 & 下一步",
    backtrack: "返回",
    total: "总计",
    item: "条",
    max_length_side: "最长边",
    second_length_side: "次长边",
    least_length_side: "最短边",
    upload: "上传",
    change:"更改",
    upload_file: "上传文件",
    confirm_in_plan: "确认入库计划",
    confirm: "确定",
    immediate_payment: "立即支付",
    pay_later: "稍后支付",
    confirm_the_import: "确认导入",
    import: "导入",
    cancel: "取消",
    close: "关闭",
    search: "搜索",
    edit: "编辑",
    query: "查询",
    save: "保存",
    saved_successfully: "保存成功",
    details: "详情",
    exception: "异常",

    currency: "币种",
    country: "国家",
    destination_country: "目的国家",
    province: "省/州",
    city: "市",
    county: "区县",

    create: "创建",
    delete: "删除",
    please_select: "请选择",
    necessary: "需要",
    unnecessary: "不需要",

    "403_cue_words": "对不起，您没有权限访问此页面。",
    "404_cue_words": "对不起，您访问的页面不存在。",
    "500_cue_words": "对不起，服务器出问题了。",
    back_home: "返回主页",

    remove: "移除",
    remove_success: "移除成功",
    delete_success: "删除成功",
    postcode: "邮编",
    amend_success: "修改成功",
    operation: "操作",

    add: "添加",
    added: "已添加",
    add_success: "添加成功",
    create_success: "创建成功",
    completed: "已完成",
    select: "选择",
    selected: "已选择",
    upload_img: "上传图片",
    bulk_print_box_label: "批量打印箱标",
    print_box_label: "打印箱标",

    Are_you_sure_to_delete_this_piece_of_data: "你确定要删除这条数据吗?",
    Are_you_sure_to_delete_p0: "你确定要删除{0}吗?",
    are_you_sure_cancel: "你确定要取消吗?",
    p0_must_be_an_integer: "{0}必须为整数",
    p0_please_enter_at_least_p1_digits: "{0}请至少输入{1}位",
    p0_products: "{0}个产品",

    close_current: "关闭当前",
    close_others: "关闭其他",
    close_all: "关闭所有",

    data_modification_succeeded: "修复数据成功",
    system_automatically_helps_repair: "系统是否自动帮修复?",
    current_size_fill_in_error: "当前尺寸填写错误.",
    uploaded_img_file_format_incorrect: "请上传jpeg,png,jpg格式图片",
    submit_confirmation: "确认提交",
    refresh: "刷新",
    top_up: "充值",
    input_is_not_in_the_correct_format: "输入的格式不正确,只允许数字、字母、-、_、\\和/",
    the_input_length_is_too_long: "输入长度过长",
    user_defined_box_no: "自定义箱号",
    set_user_defined_box_no_succeed: "设置自定义箱号成功",

    print: "打印",
    batch_print: "批量打印",
    se_outer_box_label: "SE外箱标签",
    product_label_manage: "产品标签管理",
    product_statistics: "产品统计",
    product_no: "货号",
    total_product: "产品总数",

    pdf_upload_hint:"注意: 上传PDF文件时, 需保持高宽比例为3:2或者2:1",
    
    export: "导出",
    i_already_know: "我已知晓",
    cautions:"注意事项",

    export_product_in_the_warehouse_time:"导出产品库龄",

    yes: "是",
    no: "否",
    version_tips: "当前为非正式版本",
    copy: "复制",
    change_SESKU:"更换SESKU",
    hint:"提示",
    nothing:"无",
    error_ause:"错误原因",
    optional:"可选填",
    download_template_file:"下载模板文件",
    data_serial_number_p0_is_incomplete:"序号为{0}的数据未填写完整",
    reservation_file_whose_serial_number_is_p0_is_not_uploaded:"序号为{0}的预约文件未上传",
    serial_number_p0_is_the_same_as_the_scannable_container_number_1:"序号{0}与序号{1}的可扫描箱号重复",
    delete_prompt:"正在进行删除操作，请再次确认",
    immediate_processing:"立即处理",
    process_later:"稍后处理",
    line_p0:"第{0}行",

    to_deal_with: "去处理",
    not_processed: "暂不处理",
    the_plan_has_an_associated_p0_does_it_need_to_be_processed: "该计划有关联的{0}, 是否需要处理?",
    p0_format_error: "{0}格式错误",
    mixed: "混装",
    single: "单一",
    count: "数量",
    related_products:"相关产品",
    signature: "签名签收",
    please_upload_file_with_size_less_than_50MB:"请上传大小不超过50MB的文件",
    export_storage_fee_details:"导出存储费明细",
    are_you_sure_remove:"是否确认移除?",
    product_name_length_limit:"请输入最大长度小于120位的产品名称",
    return_to_modification:"返回修改",
    continue:"继续",

  },
};
